import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Box,
  IconButton,
  Typography,
  Chip,
  CircularProgress,
  FormHelperText,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Divider,
  Paper,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import { addCupon, getEmpresas, getSucursales, getArticulos } from "../../services/api";
import showToast from '../../services/toast';
import Autocomplete from '@mui/material/Autocomplete';

const AddCupon = () => {
  const navigate = useNavigate();
  
  // Estado para manejar empresas y sucursales dinámicamente
  const [companies, setCompanies] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [loadingBranches, setLoadingBranches] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [aplicarATodos, setAplicarATodos] = useState(false);

  const [newCupon, setNewCupon] = useState({
    codigo: '',
    descripcion: '',
    descuento: '',
    fecha_inicio: '',
    fecha_expiracion: '',
  });

  const [articulos, setArticulos] = useState([]);
  const [selectedArticulos, setSelectedArticulos] = useState([]);

  // Fetch companies on mount
  useEffect(() => {
    const fetchCompanies = async () => {
      setLoadingCompanies(true);
      try {
        const response = await getEmpresas();
        setCompanies(
          response.data.titulares.map((company) => ({
            id: company.ruc_titular,
            nombre: company.nombre_comercial,
          }))
        );
      } catch (error) {
        console.error("Error fetching companies:", error);
      } finally {
        setLoadingCompanies(false);
      }
    };

    fetchCompanies();
  }, []);

  // Fetch branches when a company is selected
  useEffect(() => {
    if (selectedCompany) {
      const fetchBranches = async () => {
        setLoadingBranches(true);
        try {
          const response = await getSucursales(selectedCompany);
          setBranches(
            response.data.sucursales.map((branch) => ({
              id: branch.cod_sucursal,
              nombre: branch.nom_sucursal,
            }))
          );
        } catch (error) {
          console.error("Error fetching branches:", error);
        } finally {
          setLoadingBranches(false);
        }
      };

      fetchBranches();
    } else {
      setBranches([]);
      setSelectedBranches([]);
    }
  }, [selectedCompany]);

  // Fetch articulos on mount
  useEffect(() => {
    const fetchArticulos = async () => {
      try {
        const response = await getArticulos();
        // Transformamos los artículos para incluir un id y nombre más descriptivo
        const articulosFormateados = response.data.lista_articulos.map(articulo => ({
          id: articulo.sku,
          nombre: articulo.nombre_completo,
          sku: articulo.sku,
          precio: articulo.precio,
          imagen: articulo.url,
          categoria: articulo.desc_categoria
        }));
        setArticulos(articulosFormateados);
      } catch (error) {
        console.error("Error fetching artículos:", error);
      }
    };

    fetchArticulos();
  }, []);

  const handleUpdateProductoDescuento = (articuloId, descuento) => {
    const updatedArticulos = selectedArticulos.map(articulo => 
      articulo.id === articuloId ? { ...articulo, descuento } : articulo
    );
    setSelectedArticulos(updatedArticulos);
  };

  const validateForm = () => {
    const errors = {};
    debugger
    if (!newCupon.codigo) {
      errors.codigo = "El código es obligatorio";
    }
    
    if (!newCupon.descripcion) {
      errors.descripcion = "La descripción es obligatoria";
    }
    
    // Validar descuento general solo si se aplica a todos los productos
    if (aplicarATodos) {
      if (!newCupon.descuento) {
        errors.descuento = "El descuento es obligatorio";
      } else if (isNaN(newCupon.descuento) || parseFloat(newCupon.descuento) <= 0) {
        errors.descuento = "El descuento debe ser un número positivo";
      }
    }
    
    if (!newCupon.fecha_inicio) {
      errors.fecha_inicio = "La fecha de inicio es obligatoria";
    }
    
    if (!newCupon.fecha_expiracion) {
      errors.fecha_expiracion = "La fecha de expiración es obligatoria";
    } else if (new Date(newCupon.fecha_expiracion) <= new Date(newCupon.fecha_inicio)) {
      errors.fecha_expiracion = "La fecha de expiración debe ser posterior a la fecha de inicio";
    }
    
    if (selectedBranches.length === 0) {
      errors.sucursales = "Debe seleccionar al menos una sucursal";
    }

    // Validar que todos los productos seleccionados tengan un descuento
    if (!aplicarATodos && selectedArticulos.length > 0) {
      const productosInvalidos = selectedArticulos.some(articulo => !articulo.descuento);
      if (productosInvalidos) {
        errors.productosDescuento = "Todos los productos deben tener un descuento asignado";
      }
    }
    
    // Si no se aplica a todos los productos, debe seleccionar al menos un producto
    if (!aplicarATodos && selectedArticulos.length === 0) {
      errors.productosDescuento = "Debe seleccionar al menos un producto o marcar la opción 'Aplicar a todos los productos'";
    }
    
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      setOpenConfirmationModal(true);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleBranchChange = (event) => {
    setSelectedBranches(event.target.value);
  };

  const handleConfirmAddCupon = async () => {
    setLoadingConfirm(true);
    try {
      const cuponData = {
        codigo: newCupon.codigo,
        descripcion: newCupon.descripcion,
        descuento: aplicarATodos ? parseFloat(newCupon.descuento) : null,
        fecha_inicio: newCupon.fecha_inicio,
        fecha_expiracion: newCupon.fecha_expiracion,
        sucursales: selectedBranches,
        aplicar_a_todos: aplicarATodos,
        productos_descuento: aplicarATodos ? [] : selectedArticulos.map(articulo => ({
          cod_producto: articulo.id.split('.')[0],
          nom_producto: articulo.nombre,
          descuento: parseFloat(articulo.descuento)
        })),
      };

      console.log("Datos del cupón a enviar:", cuponData);
      await addCupon(cuponData);
      showToast.success("Cupón creado exitosamente");
      navigate("/app/cupones");
    } catch (error) {
      console.error("Error creating cupon:", error);
      showToast.error("Error al crear el cupón");
    } finally {
      setLoadingConfirm(false);
      setOpenConfirmationModal(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCupon({
      ...newCupon,
      [name]: value,
    });
    
    // Limpiar el error específico cuando el usuario comienza a escribir
    if (formErrors[name]) {
      setFormErrors({
        ...formErrors,
        [name]: null,
      });
    }
  };

  const handleAplicarATodosChange = (event) => {
    setAplicarATodos(event.target.checked);
    if (event.target.checked) {
      // Si se aplica a todos, limpiamos los productos seleccionados
      setSelectedArticulos([]);
      // Limpiamos el error de productos si existe
      if (formErrors.productosDescuento) {
        setFormErrors({
          ...formErrors,
          productosDescuento: null,
        });
      }
    } else {
      // Si no se aplica a todos, limpiamos el descuento general
      setNewCupon({
        ...newCupon,
        descuento: '',
      });
      // Limpiamos el error de descuento si existe
      if (formErrors.descuento) {
        setFormErrors({
          ...formErrors,
          descuento: null,
        });
      }
    }
  };

  return (
    <Container style={{ marginLeft: "0", maxWidth: "100%" }}>
      <Box display="flex" alignItems="center" mb={2}>
        <IconButton onClick={handleGoBack} style={{ marginRight: "16px" }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" component="h1">
          Agregar Nuevo Cupón
        </Typography>
      </Box>

      <form onSubmit={handleSubmit}>
        <Paper elevation={0} sx={{ p: 3, border: "1px solid lightgray", borderRadius: "8px", mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Información General
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Código del Cupón"
                variant="outlined"
                fullWidth
                name="codigo"
                value={newCupon.codigo}
                onChange={handleInputChange}
                error={!!formErrors.codigo}
                helperText={formErrors.codigo}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Descripción"
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                name="descripcion"
                value={newCupon.descripcion}
                onChange={handleInputChange}
                error={!!formErrors.descripcion}
                helperText={formErrors.descripcion}
              />
            </Grid>
          </Grid>
        </Paper>

        <Paper elevation={0} sx={{ p: 3, border: "1px solid lightgray", borderRadius: "8px", mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Vigencia
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Fecha de Inicio"
                variant="outlined"
                fullWidth
                type="datetime-local"
                name="fecha_inicio"
                value={newCupon.fecha_inicio}
                onChange={handleInputChange}
                error={!!formErrors.fecha_inicio}
                helperText={formErrors.fecha_inicio}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Fecha de Expiración"
                variant="outlined"
                fullWidth
                type="datetime-local"
                name="fecha_expiracion"
                value={newCupon.fecha_expiracion}
                onChange={handleInputChange}
                error={!!formErrors.fecha_expiracion}
                helperText={formErrors.fecha_expiracion}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </Paper>

        <Paper elevation={0} sx={{ p: 3, border: "1px solid lightgray", borderRadius: "8px", mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Sucursales
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Empresa</InputLabel>
                <Select
                  value={selectedCompany}
                  onChange={(e) => setSelectedCompany(e.target.value)}
                  label="Empresa"
                  disabled={loadingCompanies}
                >
                  <MenuItem value="">
                    <em>Seleccionar empresa</em>
                  </MenuItem>
                  {companies.map((company) => (
                    <MenuItem key={company.id} value={company.id}>
                      {company.nombre}
                    </MenuItem>
                  ))}
                </Select>
                {loadingCompanies && (
                  <Box display="flex" justifyContent="center" mt={1}>
                    <CircularProgress size={20} />
                  </Box>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined" error={!!formErrors.sucursales}>
                <InputLabel>Sucursales</InputLabel>
                <Select
                  multiple
                  value={selectedBranches}
                  onChange={handleBranchChange}
                  label="Sucursales"
                  disabled={!selectedCompany || loadingBranches}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => {
                        const branch = branches.find(b => b.id === value);
                        return (
                          <Chip key={value} label={branch ? branch.nombre : value} />
                        );
                      })}
                    </Box>
                  )}
                >
                  {branches.map((branch) => (
                    <MenuItem key={branch.id} value={branch.id}>
                      {branch.nombre}
                    </MenuItem>
                  ))}
                </Select>
                {loadingBranches && (
                  <Box display="flex" justifyContent="center" mt={1}>
                    <CircularProgress size={20} />
                  </Box>
                )}
                {formErrors.sucursales && (
                  <FormHelperText>{formErrors.sucursales}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Paper>

        <Paper elevation={0} sx={{ p: 3, border: "1px solid lightgray", borderRadius: "8px", mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Productos con Descuento Específico
          </Typography>
          
          <FormControlLabel
            control={
              <Checkbox
                checked={aplicarATodos}
                onChange={handleAplicarATodosChange}
                color="primary"
              />
            }
            label="Aplicar a todos los productos"
            sx={{ mb: 2 }}
          />
          
          {aplicarATodos && (
            <Box sx={{ mb: 3 }}>
              <TextField
                label="Descuento General (%)"
                variant="outlined"
                fullWidth
                name="descuento"
                type="number"
                value={newCupon.descuento}
                onChange={handleInputChange}
                error={!!formErrors.descuento}
                helperText={formErrors.descuento}
              />
              <Box sx={{ mt: 2, p: 2, bgcolor: 'rgba(0, 0, 0, 0.03)', borderRadius: 1 }}>
                <Typography variant="body2" color="textSecondary">
                  El descuento general del {newCupon.descuento || '0'}% se aplicará a todos los productos.
                </Typography>
              </Box>
            </Box>
          )}
          
          {!aplicarATodos && (
            <>
              <Box sx={{ mb: 3 }}>
                <Autocomplete
                  multiple
                  options={articulos}
                  getOptionLabel={(option) => `${option.nombre} - ${option.precio}`}
                  value={selectedArticulos}
                  onChange={(event, newValue) => {
                    setSelectedArticulos(newValue);
                  }}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        {option.imagen && (
                          <img 
                            src={option.imagen} 
                            alt={option.nombre}
                            style={{ 
                              width: 40, 
                              height: 40, 
                              marginRight: 10,
                              objectFit: 'cover'
                            }}
                          />
                        )}
                        <Box>
                          <Typography variant="body1">{option.nombre}</Typography>
                          <Typography variant="caption" color="text.secondary">
                            {option.categoria} - Gs. {parseInt(option.precio).toLocaleString('es-PY')}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Buscar y seleccionar productos"
                      placeholder="Escriba para buscar productos"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
                {formErrors.productosDescuento && (
                  <Typography color="error" variant="caption" sx={{ mt: 1, display: 'block' }}>
                    {formErrors.productosDescuento}
                  </Typography>
                )}
              </Box>

              {selectedArticulos.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Productos seleccionados:
                  </Typography>
                  {selectedArticulos.map((articulo, index) => (
                    <React.Fragment key={articulo.id}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
                        <Box>
                          <Typography variant="body1">
                            {articulo.nombre}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            Precio actual: Gs. {parseInt(articulo.precio).toLocaleString('es-PY')}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <TextField
                            label="Descuento (%)"
                            variant="outlined"
                            size="small"
                            type="number"
                            sx={{ width: 150, mr: 2 }}
                            value={articulo.descuento || ''}
                            onChange={(e) => {
                              handleUpdateProductoDescuento(articulo.id, e.target.value);
                            }}
                          />
                          <IconButton
                            onClick={() => {
                              setSelectedArticulos(selectedArticulos.filter(a => a.id !== articulo.id));
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Box>
                      {index < selectedArticulos.length - 1 && <Divider sx={{ mb: 1 }} />}
                    </React.Fragment>
                  ))}
                </Box>
              )}
            </>
          )}
        </Paper>

        <Box display="flex" justifyContent="flex-end" mt={3} mb={5}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            size="large"
          >
            Crear Cupón
          </Button>
        </Box>
      </form>

      {/* Modal de confirmación */}
      <Dialog
        open={openConfirmationModal}
        onClose={() => !loadingConfirm && setOpenConfirmationModal(false)}
      >
        <DialogTitle>Confirmar Creación de Cupón</DialogTitle>
        <DialogContent>
          <Typography>
            ¿Está seguro que desea crear este cupón con la siguiente información?
          </Typography>
          <Box mt={2}>
            <Typography variant="subtitle2">Código: {newCupon.codigo}</Typography>
            {aplicarATodos && (
              <Typography variant="subtitle2">Descuento: {newCupon.descuento}%</Typography>
            )}
            <Typography variant="subtitle2">
              Vigencia: {newCupon.fecha_inicio} - {newCupon.fecha_expiracion}
            </Typography>
            <Typography variant="subtitle2">
              Sucursales: {selectedBranches.length} seleccionadas
            </Typography>
            <Typography variant="subtitle2">
              {aplicarATodos 
                ? "Aplicar a todos los productos" 
                : `Productos con descuento específico: ${selectedArticulos.length}`}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => !loadingConfirm && setOpenConfirmationModal(false)} 
            disabled={loadingConfirm}
          >
            Cancelar
          </Button>
          <Button 
            onClick={handleConfirmAddCupon} 
            color="primary" 
            variant="contained"
            disabled={loadingConfirm}
            startIcon={loadingConfirm ? <CircularProgress size={20} /> : null}
          >
            {loadingConfirm ? "Creando..." : "Confirmar"}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AddCupon; 