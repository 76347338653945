import React, { useEffect, useState, useRef } from 'react';
import { TextField, Button, Menu, MenuItem as MuiMenuItem, Typography, Box } from '@mui/material';
import { Add as AddIcon, Visibility as EyeIcon } from '@mui/icons-material'; // Cambié Watch por Visibility para icono de vista
import { getVariables } from '../../../services/api'; // Importa el servicio

const MessageInput = ({ message, setMessage }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataList, setDataList] = useState([]); // Inicializa como array vacío
  const [preview, setPreview] = useState(""); // Estado para la vista previa del mensaje
  const messageRef = useRef(null); // Ref para el TextField del mensaje

  // Fetch de variables al montar el componente
  useEffect(() => {
    const fetchVariables = async () => {
      try {
        const response = await getVariables("push"); // Llama al servicio
        setDataList(Array.isArray(response.data) ? response.data : []); // Asegura que el estado sea un array
      } catch (error) {
        console.error('Error fetching variables:', error);
        setDataList([]); // Asegura que dataList es un array vacío en caso de error
      }
    };

    fetchVariables(); // Llama a la función
  }, []); // Se ejecuta solo una vez, al montar el componente

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); // Abre el menú
  };

  const handleClose = () => {
    setAnchorEl(null); // Cierra el menú
  };

  const handleSelectDato = (dato) => {
    const textField = messageRef.current;
    const cursorPosition = textField.selectionStart; // Obtiene la posición del cursor

    const newMessage = message.slice(0, cursorPosition) +
      `{${dato.key}}` + // Inserta el 'key' de la variable
      message.slice(cursorPosition);

    setMessage(newMessage);
    handleClose();
    textField.focus(); // Focaliza el campo de texto
    textField.selectionStart = textField.selectionEnd = cursorPosition + dato.key.length + 2; // Ajusta la posición del cursor
  };

  const generatePreview = () => {
    let previewMessage = message;

    dataList.forEach(dato => {
      const regex = new RegExp(`{${dato.key}}`, 'g');
      previewMessage = previewMessage.replace(regex, dato.description);
    });

    setPreview(previewMessage);
  };

  return (
    <div>
      <TextField
        label="Mensaje"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        inputRef={messageRef} // Asigna la referencia al TextField
        style={{ marginBottom: "16px" }}
      />
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="outlined"
        color="primary"
        startIcon={<AddIcon />} // Agrega el ícono de +
        style={{ marginBottom: "16px" }}
      >
        Agregar Dato
      </Button>
      {message && ( // Muestra el botón solo si hay mensaje
        <Button
          onClick={generatePreview}
          variant="outlined" // Usa el estilo "outlined" para mayor sutileza
          color="default" // Cambia el color a gris
          style={{ marginBottom: "16px", marginLeft: "8px" }}
          startIcon={<EyeIcon />} // Agrega el ícono de vista
        >
          Preview del mensaje
        </Button>
      )}

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Array.isArray(dataList) && dataList.length > 0 ? ( // Verifica que dataList es un array y tiene elementos
          dataList.map((dato, index) => (
            <MuiMenuItem key={index} onClick={() => handleSelectDato(dato)}>
              <Box display="flex" flexDirection="column">
                <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                  {dato.key} {/* Muestra el key como título principal */}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {dato.description} {/* Muestra la descripción como subtítulo más pequeño */}
                </Typography>
              </Box>
            </MuiMenuItem>
          ))
        ) : (
          <MuiMenuItem disabled>
            <Typography variant="body2" color="textSecondary">
              No hay datos disponibles
            </Typography>
          </MuiMenuItem>
        )}
      </Menu>

      {preview && (
        <Box 
          marginTop="16px"
          sx={{
            backgroundColor: '#f5f5f5', // Color gris claro
            borderRadius: '8px', // Bordes redondeados de 8px
            padding: '16px', // Padding de 16px
            border: '1px solid #ddd', // Borde sutil
          }}
        >
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
            Vista previa del mensaje:
          </Typography>
          <Typography variant="body2" style={{ marginTop: '8px' }}>
            {preview}
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default MessageInput;
