import axios from "axios";

const api = axios.create({
  baseURL: "/api/",
  headers: {
    "Content-Type": "application/json",
  },
});


export const getRecordatorios = () => {
  const url = "/recordatorios/";
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.get(url);
};

export const getRecordatorio = (id) => {
  const url = `/recordatorios/${id}/`;
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.get(url);
};

export const addRecordatorio = (recordatorio) => {
  console.log(`Making POST request to: ${api.defaults.baseURL}/recordatorios/ with data:`, recordatorio);
  return api.post("/recordatorios/", recordatorio);
};


export const getNotifications = () => {
  const url = "/notificaciones/";
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.get(url);
};

export const getNotificacion = (id) => {
  const url = `/notificaciones/${id}/`;
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.get(url);
};

export const addNotificacion = (recordatorio) => {
  console.log(`Making POST request to: ${api.defaults.baseURL}/recordatorios/ with data:`, recordatorio);
  return api.post("/notificaciones/", recordatorio);
};

export const activarNotificacion = (id) => {
  const url = `/notificaciones/${id}/activate/`;
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.patch(url);
};

export const desactivarNotificacion = (id) => {
  const url = `/notificaciones/${id}/deactivate/`;
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.patch(url);
};

export const eliminarRecordatorio = (id) => {
  const url = `/recordatorios/${id}/`;  // Incluye el id en la URL
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.delete(url);
};

// utils

export const getEmpresas = () => {
  const url = "/utils/listar_titulares/";
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.get(url);
};

export const getSucursales = (ruc) => {
  const url = `/utils/listar_sucursales/?ruc=${ruc}`;
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.get(url);
};

export const getArticulos = () => {
  const url = `/utils/listar_articulos/`; 
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.get(url);
};

export const getFeriados = () => {
  const url = "/utils/feriados/";
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.get(url);
};

export const addFeriado = (feriado) => {
  console.log(`Making POST request to: ${api.defaults.baseURL}/feriados/ with data:`, feriado);
  return api.post("/utils/feriados/", feriado);
};

export const editFeriado = (id, feriado) => {
  console.log(`Making POST request to: ${api.defaults.baseURL}/feriados/id with data:`, feriado);
  return api.put(`/utils/feriados/${id}/`, feriado);
};

export const deleteFeriado = (id) => {
  console.log(`Making POST request to: ${api.defaults.baseURL}/feriados/id`,);
  return api.delete(`/utils/feriados/${id}/`);
};

export const getVariables = (type) => {
  const url = `/notificaciones/template-variables/?type=${type}`;
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.get(url);
};

export const addDifusion = (difusion) => {
  console.log(`Making POST request to: ${api.defaults.baseURL}/difusiones/ with data:`, difusion);
  return api.post("/notificaciones/whatsapp/enviar_masivo/", difusion);
};


export const getWhatsAppTemplates = () => {
  const url = "/notificaciones/whatsapp/";
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.get(url);
};

export const getWhatsAppTemplateDetails = (templateId) => {
  const url = `/notificaciones/whatsapp/${templateId}/detalles/`;
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.get(url);
};

export const getTemplateVariableFields = () => {
  const url = `/notificaciones/whatsapp/variables/`;
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.get(url);
};

// Servicios para el módulo de Cupones
export const getCupones = (params = {}) => {
  const queryParams = new URLSearchParams();
  
  if (params.is_active !== undefined) {
    queryParams.append('is_active', params.is_active);
  }
  if (params.sucursal) {
    queryParams.append('sucursal', params.sucursal);
  }
  if (params.vigentes !== undefined) {
    queryParams.append('vigentes', params.vigentes);
  }
  if (params.producto) {
    queryParams.append('producto', params.producto);
  }
  
  const url = `/cupones/${queryParams.toString() ? `?${queryParams.toString()}` : ''}`;
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.get(url);
};

export const getCupon = (id) => {
  const url = `/cupones/${id}/`;
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.get(url);
};

export const addCupon = (cupon) => {
  console.log(`Making POST request to: ${api.defaults.baseURL}/cupones/ with data:`, cupon);
  return api.post("/cupones/", cupon);
};

export const updateCupon = (id, cupon) => {
  console.log(`Making PATCH request to: ${api.defaults.baseURL}/cupones/${id}/ with data:`, cupon);
  return api.patch(`/cupones/${id}/`, cupon);
};

export const activarCupon = (id) => {
  const url = `/cupones/${id}/activar/`;
  console.log(`Making POST request to: ${api.defaults.baseURL}${url}`);
  return api.post(url);
};

export const desactivarCupon = (id) => {
  const url = `/cupones/${id}/desactivar/`;
  console.log(`Making POST request to: ${api.defaults.baseURL}${url}`);
  return api.post(url);
};

export const getCuponesActivos = (params = {}) => {
  const queryParams = new URLSearchParams();
  
  if (params.sucursal) {
    queryParams.append('sucursal', params.sucursal);
  }
  if (params.producto) {
    queryParams.append('producto', params.producto);
  }
  
  const url = `/cupones/activos/${queryParams.toString() ? `?${queryParams.toString()}` : ''}`;
  console.log(`Making GET request to: ${api.defaults.baseURL}${url}`);
  return api.get(url);
};

export default api;
