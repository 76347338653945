import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getCupon, activarCupon, desactivarCupon } from '../../services/api';
import { 
  Box, 
  Typography, 
  Card, 
  CardContent, 
  Grid, 
  Divider, 
  Chip, 
  CircularProgress, 
  IconButton, 
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';

const CuponDetalle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [cupon, setCupon] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState(false);

  useEffect(() => {
    const fetchCupon = async () => {
      try {
        const response = await getCupon(id);
        console.log("Datos del cupón recibidos:", response.data);
        setCupon(response.data);
      } catch (error) {
        setError('Error al cargar el cupón');
      } finally {
        setLoading(false);
      }
    };

    fetchCupon();
  }, [id]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const toggleCuponStatus = async () => {
    setLoadingStatus(true);
    try {
      if (cupon.is_active) {
        await desactivarCupon(id);
      } else {
        await activarCupon(id);
      }
      setCupon((prev) => ({ ...prev, is_active: !prev.is_active }));
    } catch (error) {
      console.error('Error al actualizar el estado del cupón:', error);
    } finally {
      setLoadingStatus(false);
    }
  };

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return date.toLocaleString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch (error) {
      return dateString;
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Typography variant="h6" color="error">
        {error}
      </Typography>
    </Box>;
  }

  if (!cupon) {
    return <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Typography variant="h6" color="textSecondary">
        No se encontró el cupón.
      </Typography>
    </Box>;
  }

  // Determinar si el cupón se aplica a todos los productos o solo a productos específicos
  // Si aplicar_a_todos es true o si descuento tiene un valor y productos_descuento está vacío, entonces es para todos
  // Si descuento es null o productos_descuento tiene elementos, entonces es para productos específicos
  const esParaTodosLosProductos = cupon.aplicar_a_todos === true || 
    (cupon.descuento !== null && (!Array.isArray(cupon.productos_descuento) || cupon.productos_descuento.length === 0));
  
  const esEspecifico = !esParaTodosLosProductos;

  console.log("Datos del cupón:", {
    descuento: cupon.descuento,
    aplicar_a_todos: cupon.aplicar_a_todos,
    productos_descuento: cupon.productos_descuento,
    esParaTodosLosProductos,
    esEspecifico
  });

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2, paddingTop: 0 }}>
      <Card sx={{ width: '100%', boxShadow: '0px 0px 8px rgba(211, 211, 211, 0.6)' }}>
        <CardContent>
          {/* Título del cupón y botón de activación/desactivación */}
          <Box display="flex" alignItems="center" mb={2}>
            <IconButton onClick={handleGoBack} style={{ marginRight: "16px" }}>
              <ArrowBackIcon />
            </IconButton>
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" fontWeight="bold">
                Cupón: {cupon.codigo}
              </Typography>
              <Button
                variant="contained"
                color={cupon.is_active ? "error" : "success"}
                startIcon={loadingStatus ? <CircularProgress size={20} color="inherit" /> : <PowerSettingsNewIcon />}
                onClick={toggleCuponStatus}
                disabled={loadingStatus}
              >
                {loadingStatus ? (cupon.is_active ? "Desactivando..." : "Activando...") : (cupon.is_active ? "Desactivar Cupón" : "Activar Cupón")}
              </Button>
            </Box>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Detalles del Cupón */}
          <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
            Detalles del Cupón
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="body2" color="textSecondary">
                Código
              </Typography>
              <Typography>{cupon.codigo}</Typography>
            </Grid>

            {!esEspecifico && (
              <Grid item xs={6}>
                <Typography variant="body2" color="textSecondary">
                  Descuento General
                </Typography>
                <Typography>{cupon.descuento}%</Typography>
              </Grid>
            )}

            <Grid item xs={6}>
              <Typography variant="body2" color="textSecondary">
                Fecha de Inicio
              </Typography>
              <Typography>{formatDate(cupon.fecha_inicio)}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body2" color="textSecondary">
                Fecha de Expiración
              </Typography>
              <Typography>{formatDate(cupon.fecha_expiracion)}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Descripción
              </Typography>
              <Typography>{cupon.descripcion}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Sucursales
              </Typography>
              <Typography>{cupon.sucursales?.join(", ")}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Aplicación del Descuento
              </Typography>
              <Typography>
                {esEspecifico 
                  ? "Se aplica solo a productos específicos" 
                  : "Se aplica a todos los productos"}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                Estado
              </Typography>
              <Chip label={cupon.is_active ? "Cupón Activo" : "Cupón Inactivo"} color={cupon.is_active ? "success" : "default"} size="small" />
            </Grid>
          </Grid>

          {/* Productos con descuento específico */}
          {esEspecifico && cupon.productos_descuento && cupon.productos_descuento.length > 0 && (
            <>
              <Divider sx={{ my: 2 }} />
              <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                Productos con Descuento Específico
              </Typography>
              <TableContainer component={Paper} variant="outlined">
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>Código de Producto</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>Nombre del Producto</TableCell>
                      <TableCell style={{ fontWeight: "bold" }}>Descuento (%)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cupon.productos_descuento.map((producto, index) => (
                      <TableRow key={index}>
                        <TableCell>{producto.cod_producto}</TableCell>
                        <TableCell>{producto.nom_producto || "No disponible"}</TableCell>
                        <TableCell>{producto.descuento}%</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default CuponDetalle; 