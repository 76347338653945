import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  CircularProgress,
  Box,
  Grid,
  Paper,
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { Link } from "react-router-dom";
import { getCupones, getEmpresas, getSucursales } from "../../services/api";

const Cupones = () => {
  const [filter, setFilter] = useState("");
  const [cupones, setCupones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [cuponesPerPage] = useState(10);
  const [companies, setCompanies] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [loadingBranches, setLoadingBranches] = useState(false);
  const [filterActive, setFilterActive] = useState("");

  useEffect(() => {
    const fetchCupones = async () => {
      try {
        const params = {};
        if (filterActive !== "") {
          params.is_active = filterActive === "active";
        }
        if (selectedBranch) {
          params.sucursal = selectedBranch;
        }
        
        const response = await getCupones(params);
        setCupones(Array.isArray(response.data) ? response.data : []);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCupones();
  }, [filterActive, selectedBranch]);

  // Fetch companies on mount
  useEffect(() => {
    const fetchCompanies = async () => {
      setLoadingCompanies(true);
      try {
        const response = await getEmpresas();
        setCompanies(
          response.data.titulares.map((company) => ({
            id: company.ruc_titular,
            nombre: company.nombre_comercial,
          }))
        );
      } catch (error) {
        console.error("Error fetching companies:", error);
      } finally {
        setLoadingCompanies(false);
      }
    };

    fetchCompanies();
  }, []);

  // Fetch branches when a company is selected
  useEffect(() => {
    if (selectedCompany) {
      const fetchBranches = async () => {
        setLoadingBranches(true);
        try {
          const response = await getSucursales(selectedCompany);
          setBranches(
            response.data.sucursales.map((branch) => ({
              id: branch.cod_sucursal,
              nombre: branch.nom_sucursal,
            }))
          );
        } catch (error) {
          console.error("Error fetching branches:", error);
        } finally {
          setLoadingBranches(false);
        }
      };

      fetchBranches();
      setSelectedBranch("");
    } else {
      setBranches([]);
      setSelectedBranch("");
    }
  }, [selectedCompany]);

  if (loading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );

  if (error) setError(true);

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  const renderStatusPill = (isActive) => {
    const pillStyle = {
      display: "inline-block",
      padding: "4px 8px",
      borderRadius: "16px",
      fontSize: "12px",
      fontWeight: "bold",
      color: "#fff",
      backgroundColor: isActive ? "#4caf50" : "#f44336",
    };
    return <span style={pillStyle}>{isActive ? "Activo" : "Inactivo"}</span>;
  };

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return date.toLocaleString('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch (error) {
      return dateString;
    }
  };

  const indexOfLastCupon = currentPage * cuponesPerPage;
  const indexOfFirstCupon = indexOfLastCupon - cuponesPerPage;
  
  const filteredCupones = Array.isArray(cupones)
    ? cupones.filter(
        (item) =>
          item.codigo.toLowerCase().includes(filter.toLowerCase()) ||
          item.descripcion.toLowerCase().includes(filter.toLowerCase())
      )
    : [];
    
  const currentCupones = filteredCupones.slice(
    indexOfFirstCupon,
    indexOfLastCupon
  );

  const totalPages = Math.ceil(filteredCupones.length / cuponesPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
  };

  const handleBranchChange = (event) => {
    setSelectedBranch(event.target.value);
  };

  const handleFilterActiveChange = (event) => {
    setFilterActive(event.target.value);
  };

  return (
    <Container style={{ marginLeft: "0", maxWidth: "100%" }}>
      <h1 style={{ marginTop: 0 }}>Cupones</h1>
      <Grid container spacing={2} alignItems="center" style={{ marginBottom: "16px" }}>
        <Grid item xs={12} sm={6} md={2}>
          <TextField
            label="Filtrar"
            variant="outlined"
            fullWidth
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Estado</InputLabel>
            <Select
              value={filterActive}
              onChange={handleFilterActiveChange}
              label="Estado"
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="active">Activos</MenuItem>
              <MenuItem value="inactive">Inactivos</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Empresa</InputLabel>
            <Select
              value={selectedCompany}
              onChange={handleCompanyChange}
              label="Empresa"
              disabled={loadingCompanies}
            >
              <MenuItem value="">
                <em>Seleccionar empresa</em>
              </MenuItem>
              {companies.map((company) => (
                <MenuItem key={company.id} value={company.id}>
                  {company.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Sucursal</InputLabel>
            <Select
              value={selectedBranch}
              onChange={handleBranchChange}
              label="Sucursal"
              disabled={!selectedCompany || loadingBranches}
            >
              <MenuItem value="">
                <em>Todas las sucursales</em>
              </MenuItem>
              {branches.map((branch) => (
                <MenuItem key={branch.id} value={branch.id}>
                  {branch.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ textAlign: "right" }}>
          <Button variant="contained" color="primary" component={Link} to="/app/cupones/agregar">
            Agregar Cupón
          </Button>
        </Grid>
      </Grid>
      { error ? (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="50vh">
            <ReportProblemOutlinedIcon style={{ fontSize: 50, color: "gray" }} />
            <Typography variant="h6" color="textSecondary">
              Error al cargar los cupones.
            </Typography>
          </Box>
      ) : filteredCupones.length === 0 ? (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="50vh">
          <HelpOutlineIcon style={{ fontSize: 50, color: "gray" }} />
          <Typography variant="h6" color="textSecondary">
            No existen cupones
          </Typography>
        </Box>
      ) : (
        <>
          <TableContainer
            component={Paper}
            elevation={0}
            sx={{
              border: "1px solid lightgray",
              borderRadius: "8px",
              backgroundColor: "#fff",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Código</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Descripción</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Descuento</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Fecha Inicio</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Fecha Expiración</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Sucursales</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Estado</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentCupones.map((item, index) => (
                  <TableRow
                    key={index}
                    className="table-row-hover"
                    sx={{
                      backgroundColor: "#fff",
                      "&:hover": {
                        backgroundColor: "#f0f0f0",
                      },
                    }}
                  >
                    <TableCell>{item.codigo}</TableCell>
                    <TableCell>{truncateText(item.descripcion, 50)}</TableCell>
                    <TableCell>{item.descuento}%</TableCell>
                    <TableCell>{formatDate(item.fecha_inicio)}</TableCell>
                    <TableCell>{formatDate(item.fecha_expiracion)}</TableCell>
                    <TableCell>{item.sucursales.join(", ")}</TableCell>
                    <TableCell>{renderStatusPill(item.is_active)}</TableCell>
                    <TableCell>
                      <IconButton component={Link} to={`/app/cupones/${item.id}`}>
                        <ChevronRightIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* Sección de paginación */}
          {filteredCupones.length > cuponesPerPage && (
            <Box display="flex" justifyContent="flex-end" alignItems="center" marginTop={2}>
              <Button onClick={handlePrevPage} disabled={currentPage === 1}>
                Anterior
              </Button>
              <Typography style={{ margin: "0 16px" }}>
                Página {currentPage} de {totalPages}
              </Typography>
              <Button onClick={handleNextPage} disabled={currentPage === totalPages}>
                Siguiente
              </Button>
            </Box>
          )}
        </>
      )}
    </Container>
  );
};

export default Cupones; 