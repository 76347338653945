import { Route, Routes as ReactRoutes } from "react-router-dom";
import Layout from "../components/Layout";
import Home from "../pages/Home";
import Login from "../pages/Login";
import { Notificaciones, AddNotificacion, NotificacionDetalle } from "../pages/Notificaciones";
import { Recordatorios, AddRecordatorio, DetalleRecordatorio } from "../pages/Recordatorios";
import { Feriados, AddFeriado, EditFeriado } from "../pages/Feriados";
import { Calendario } from "../pages/Calendario";
import { Difusiones } from "../pages/Difusiones";
import { Cupones, AddCupon, CuponDetalle } from "../pages/Cupones";

import {
  HomeOutlined as HomeIcon,
  CircleNotificationsOutlined as RecordatoriosIcon,
  SendOutlined as NotificationsIcon,
  BeachAccessOutlined as FeriadosIcon,
  CalendarMonth as CalendarioIcon,
  Campaign as CampaignIcon,
  LocalOffer as CuponesIcon,
} from "@mui/icons-material";


export const routesPaths = {
  login: "/",
  home: "/app/home",
  notificaciones: "/app/notificaciones",
  addNotificacion: "/app/notificaciones/agregar",
  notificacionDetalle: "/app/notificaciones/:id",
  recordatorios: "/app/recordatorios",
  addRecordatorio: "/app/recordatorios/agregar",
  recordatorioDetalle: "/app/recordatorios/:id",
  feriados: "/app/feriados",
  addFeriado: "/app/feriados/agregar",
  editFeriado: "/app/feriados/:id/editar",
  calendario: "/app/calendar",
  difusiones: "/app/difusiones",
  cupones: "/app/cupones",
  addCupon: "/app/cupones/agregar",
  cuponDetalle: "/app/cupones/:id",
};

export const sidebarItems = [
  {
    path: routesPaths.home,
    title: "Inicio",
    icon: <HomeIcon />,
  },
  {
    path: routesPaths.notificaciones,
    title: "Notificaciones",
    icon: <NotificationsIcon />,
  },
  {
    path: routesPaths.recordatorios,
    title: "Recordatorios",
    icon: <RecordatoriosIcon />,
  },
  {
    path: routesPaths.difusiones,
    title: "Difusiones",
    icon: <CampaignIcon />,
  },
  {
    path: routesPaths.cupones,
    title: "Cupones",
    icon: <CuponesIcon />,
  },
  {
    path: routesPaths.feriados,
    title: "Feriados",
    icon: <FeriadosIcon />,
  },
  {
    path: routesPaths.calendario,
    title: "Calendario",
    icon: <CalendarioIcon />,
  },
];

export const RoutesConfig = () => (
  <ReactRoutes>
    <Route path={routesPaths.login} element={<Login />} />
    <Route path="/app" element={<Layout />}>
      <Route path={routesPaths.home} element={<Home />} />
      <Route path={routesPaths.notificaciones} element={<Notificaciones />} />
      <Route path={routesPaths.addNotificacion} element={<AddNotificacion />} />
      <Route path={routesPaths.notificacionDetalle} element={<NotificacionDetalle />} />
      <Route path={routesPaths.recordatorios} element={<Recordatorios />} />
      <Route path={routesPaths.addRecordatorio} element={<AddRecordatorio />} />
      <Route path={routesPaths.recordatorioDetalle} element={<DetalleRecordatorio />} />
      <Route path={routesPaths.difusiones} element={<Difusiones />} />
      <Route path={routesPaths.feriados} element={<Feriados />} />
      <Route path={routesPaths.addFeriado} element={<AddFeriado />} />
      <Route path={routesPaths.editFeriado} element={<EditFeriado />} />
      <Route path={routesPaths.calendario} element={<Calendario />} />
      <Route path={routesPaths.cupones} element={<Cupones />} />
      <Route path={routesPaths.addCupon} element={<AddCupon />} />
      <Route path={routesPaths.cuponDetalle} element={<CuponDetalle />} />
    </Route>
  </ReactRoutes>
);
