import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Box,
  Typography,
  Chip,
  CircularProgress,
  FormHelperText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Divider,
} from "@mui/material";
import { getEmpresas, getSucursales, getWhatsAppTemplates, addDifusion, getWhatsAppTemplateDetails, getVariables } from "../../services/api";
import showToast from '../../services/toast';

const Difusiones = () => {
  const [companies, setCompanies] = useState([]);
  const [branches, setBranches] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [templateDetails, setTemplateDetails] = useState(null);
  const [loadingTemplateDetails, setLoadingTemplateDetails] = useState(false);
  const [variableValues, setVariableValues] = useState({});
  const [variableOptions, setVariableOptions] = useState([]);
  const [loadingVariableOptions, setLoadingVariableOptions] = useState(false);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [loadingBranches, setLoadingBranches] = useState(false);
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    const fetchCompanies = async () => {
      setLoadingCompanies(true);
      try {
        const response = await getEmpresas();
        setCompanies(response.data.titulares.map(company => ({
          id: company.ruc_titular,
          nombre: company.nombre_comercial
        })));
      } catch (error) {
        console.error('Error fetching companies:', error);
        showToast.error('Error al cargar las empresas');
      } finally {
        setLoadingCompanies(false);
      }
    };

    const fetchTemplates = async () => {
      setLoadingTemplates(true);
      try {
        const response = await getWhatsAppTemplates();
        setTemplates(Array.isArray(response.data.data) ? response.data.data : []);
      } catch (error) {
        console.error('Error fetching templates:', error);
        showToast.error('Error al cargar las plantillas de WhatsApp');
        setTemplates([]);
      } finally {
        setLoadingTemplates(false);
      }
    };

    const fetchVariableOptions = async () => {
      setLoadingVariableOptions(true);
      try {
        const response = await getVariables("whatsapp");
        console.log('Variable options response:', response.data);
        setVariableOptions(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error('Error fetching variable options:', error);
        showToast.error('Error al cargar las opciones de variables');
        setVariableOptions([]);
      } finally {
        setLoadingVariableOptions(false);
      }
    };

    fetchCompanies();
    fetchTemplates();
    fetchVariableOptions();
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      const fetchBranches = async () => {
        setLoadingBranches(true);
        try {
          const response = await getSucursales(selectedCompany);
          setBranches(response.data.sucursales.map(branch => ({
            id: branch.cod_sucursal,
            nombre: branch.nom_sucursal
          })));
        } catch (error) {
          console.error('Error fetching branches:', error);
          showToast.error('Error al cargar las sucursales');
        } finally {
          setLoadingBranches(false);
        }
      };

      fetchBranches();
    }
  }, [selectedCompany]);

  const handleSendDifusion = (event) => {
    event.preventDefault();
    if (validateForm()) {
      setOpenConfirmationModal(true);
    }
  };

  const handleConfirmSendDifusion = async () => {
    setLoadingConfirm(true);
    try {
      // Preparar los datos de las variables en el formato requerido
      const variablesData = {};
      
      if (templateDetails && templateDetails.variables) {
        templateDetails.variables.forEach((variable, index) => {
          if (variableValues[index]) {
            variablesData[index + 1] = variableValues[index];
          }
        });
      }
      
      const difusionData = {
        empresa: selectedCompany,
        sucursales: selectedBranches,
        template_id: selectedTemplate,
        variables: variablesData
      };
      
      console.log('Sending difusion data:', difusionData);
      
      await addDifusion(difusionData);
      showToast.success('Difusión enviada con éxito');
      setOpenConfirmationModal(false);
      
      // Resetear el formulario
      setSelectedCompany('');
      setSelectedBranches([]);
      setSelectedTemplate('');
      setTemplateDetails(null);
      setVariableValues({});
    } catch (error) {
      console.error('Error al enviar la difusión:', error);
      showToast.error('Error al enviar la difusión');
    } finally {
      setLoadingConfirm(false);
    }
  };

  const handleBranchChange = (event) => {
    const { value } = event.target;
    setSelectedBranches(typeof value === 'string' ? value.split(',') : value);
  };

  const fetchTemplateDetails = async (templateId) => {
    setLoadingTemplateDetails(true);
    try {
      const response = await getWhatsAppTemplateDetails(templateId);
      setTemplateDetails(response.data);
    } catch (error) {
      console.error('Error fetching template details:', error);
      showToast.error('Error al cargar los detalles de la plantilla');
      setTemplateDetails(null);
    } finally {
      setLoadingTemplateDetails(false);
    }
  };

  const handleTemplateChange = (event) => {
    const templateId = event.target.value;
    setSelectedTemplate(templateId);
    // Reiniciar los valores de las variables cuando cambia la plantilla
    setVariableValues({});
    if (templateId) {
      fetchTemplateDetails(templateId);
    } else {
      setTemplateDetails(null);
    }
  };

  const handleVariableChange = (index, value) => {
    setVariableValues(prev => ({
      ...prev,
      [index]: value
    }));
  };

  const validateForm = () => {
    const errors = {};
    if (!selectedCompany) {
      errors.company = "Debe seleccionar una empresa";
    }
    if (selectedBranches.length === 0) {
      errors.branches = "Debe seleccionar al menos una sucursal";
    }
    if (!selectedTemplate) {
      errors.template = "Debe seleccionar una plantilla";
    }
    
    // Validar que todas las variables tengan un valor seleccionado
    if (templateDetails && templateDetails.variables) {
      const missingVariables = [];
      
      templateDetails.variables.forEach((variable, index) => {
        if (!variableValues[index]) {
          missingVariables.push(index + 1);
        }
      });
      
      if (missingVariables.length > 0) {
        errors.variables = `Debe seleccionar un valor para ${missingVariables.length === 1 ? 'la variable' : 'las variables'} ${missingVariables.join(', ')}`;
      }
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <Container style={{ marginLeft: "0", maxWidth: "100%" }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" style={{ fontWeight: 'bold', marginBottom: '24px' }}>
          Enviar Difusión
        </Typography>
      </Box>

      <form onSubmit={handleSendDifusion}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} className="grid-left">
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" style={{ fontWeight: 'bold', color: '#1976d2' }}>
                Paso 1: Definir destino
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                Seleccione la empresa y las sucursales a las que desea enviar la difusión
              </Typography>
            </Box>

            <FormControl fullWidth style={{ marginBottom: "24px" }} required error={Boolean(formErrors.company)}>
              <InputLabel>Empresa</InputLabel>
              <Select
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value)}
                displayEmpty
              >
                <MenuItem value="" disabled></MenuItem>
                {loadingCompanies ? (
                  <MenuItem disabled><CircularProgress size={24} /></MenuItem>
                ) : (
                  companies.map((company) => (
                    <MenuItem key={company.id} value={company.id}>{company.nombre}</MenuItem>
                  ))
                )}
              </Select>
              <FormHelperText>{formErrors.company}</FormHelperText>
            </FormControl>

            {selectedCompany && (
              <FormControl fullWidth style={{ marginBottom: "24px" }} required error={Boolean(formErrors.branches)}>
                <InputLabel>Sucursal(es)</InputLabel>
                <Select
                  multiple
                  value={selectedBranches}
                  onChange={handleBranchChange}
                  displayEmpty
                  required
                >
                  <MenuItem value="" disabled></MenuItem>
                  {loadingBranches ? (
                    <MenuItem disabled><CircularProgress size={24} /></MenuItem>
                  ) : (
                    branches.map((branch) => (
                      <MenuItem key={branch.id} value={branch.id}>{branch.nombre}</MenuItem>
                    ))
                  )}
                </Select>
                <FormHelperText>{formErrors.branches}</FormHelperText>
              </FormControl>
            )}

            {selectedBranches.length > 0 && (
              <Box sx={{ mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Sucursales seleccionadas:
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {selectedBranches.map((branchId) => {
                    const branch = branches.find(branch => branch.id === branchId);
                    return branch ? (
                      <Chip 
                        key={branch.id} 
                        label={branch.nombre} 
                        color="primary" 
                        variant="outlined" 
                      />
                    ) : null;
                  })}
                </Box>
              </Box>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h6" style={{ fontWeight: 'bold', color: '#1976d2' }}>
                Paso 2: Seleccionar plantilla
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                Elija la plantilla de WhatsApp que desea utilizar para la difusión
              </Typography>
            </Box>

            <FormControl fullWidth required error={Boolean(formErrors.template)} style={{ marginBottom: "24px" }}>
              <InputLabel>Plantilla de WhatsApp</InputLabel>
              <Select
                value={selectedTemplate}
                onChange={handleTemplateChange}
                displayEmpty
              >
                <MenuItem value="" disabled></MenuItem>
                {loadingTemplates ? (
                  <MenuItem disabled><CircularProgress size={24} /></MenuItem>
                ) : templates.length > 0 ? (
                  templates.map((template) => (
                    <MenuItem 
                      key={template.id} 
                      value={template.id}
                      disabled={template.status !== 'APPROVED'}
                    >
                      {template.name} {template.status !== 'APPROVED' && `(${template.status})`}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No hay plantillas disponibles</MenuItem>
                )}
              </Select>
              <FormHelperText>{formErrors.template}</FormHelperText>
            </FormControl>

            {loadingTemplateDetails ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                <CircularProgress />
              </Box>
            ) : templateDetails && (
              <Paper sx={{ p: 2, mt: 2 }}>
                <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
                  Detalles de la Plantilla
                </Typography>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    Nombre: {templateDetails.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    Categoría: {templateDetails.category}
                  </Typography>
                </Box>
                <Typography variant="subtitle2" gutterBottom>
                  Contenido del mensaje:
                </Typography>
                <Paper 
                  variant="outlined" 
                  sx={{ 
                    p: 2, 
                    backgroundColor: '#f5f5f5',
                    whiteSpace: 'pre-wrap',
                    fontFamily: 'monospace'
                  }}
                >
                  {templateDetails.body_text}
                </Paper>

                {templateDetails.variables && templateDetails.variables.length > 0 && (
                  <Box sx={{ mt: 3 }}>
                    <Divider sx={{ mb: 2 }} />
                    <Typography variant="subtitle2" gutterBottom sx={{ fontWeight: 'bold' }}>
                      Configurar Variables
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                      Seleccione qué información se utilizará para cada variable de la plantilla
                    </Typography>
                    
                    <Box>
                      {templateDetails.variables.map((variable, index) => (
                        <FormControl 
                          key={index}
                          fullWidth 
                          required 
                          error={Boolean(formErrors.variables)}
                          sx={{ mb: 2 }}
                        >
                          <InputLabel>Variable {index + 1}</InputLabel>
                          <Select
                            value={variableValues[index] || ''}
                            onChange={(e) => handleVariableChange(index, e.target.value)}
                            label={`Variable ${index + 1}`}
                          >
                            <MenuItem value="" disabled>
                              <em>Seleccione un campo</em>
                            </MenuItem>
                            {loadingVariableOptions ? (
                              <MenuItem disabled>
                                <CircularProgress size={20} /> Cargando opciones...
                              </MenuItem>
                            ) : variableOptions.length > 0 ? (
                              variableOptions.map((option) => (
                                <MenuItem key={option.key} value={option.key}>
                                  {option.description} ({option.key})
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem disabled>No hay opciones disponibles</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      ))}
                    </Box>
                    {formErrors.variables && (
                      <FormHelperText error>{formErrors.variables}</FormHelperText>
                    )}
                  </Box>
                )}
              </Paper>
            )}
          </Grid>
        </Grid>

        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            disabled={loadingConfirm}
          >
            {loadingConfirm ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Enviar Difusión'
            )}
          </Button>
        </Box>
      </form>

      {/* Modal de Confirmación */}
      <Dialog
        open={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
      >
        <DialogTitle>
          <Typography variant="h5">
            Confirmar Envío
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            ¿Está seguro de que desea enviar esta difusión?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenConfirmationModal(false)}
            className="cancel-button"
            variant="outlined"
            disabled={loadingConfirm}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleConfirmSendDifusion}
            className="confirm-button"
            variant="contained"
            disabled={loadingConfirm}
          >
            {loadingConfirm ? <CircularProgress size={24} color="inherit" /> : 'Confirmar'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Difusiones; 